import React from 'react';
import './Project.css';
import Icon from '../img/ProgettoIngSoftware.webp';
import Image1 from '../img/Screens/IngSoftware3.webp';
import Image2 from '../img/Screens/IngSoftware2.webp';
import Image4 from '../img/Screens/IngSoftware4.webp';
import Image5 from '../img/Screens/IngSoftware5.webp';
import Image6 from '../img/Screens/IngSoftware6.webp';
import Image7 from '../img/Screens/IngSoftware7.webp';

const Project1 = () => {
  return (
    <div className="project-container">
      <h1 className="project-title">Progetto di Ingegneria del Software</h1>
      <div className="project-content">
        <div className="project-left-column">
        <img src={Icon} alt="Progetto Ing Software Icon" className="project-icon" />
          <ul className="project-details">
            <li><strong>Nome:</strong> Prova Finale: Progetto di Ingegneria del Software</li>
            <li><strong>Voto:</strong> 26</li>
            <li><strong>Technologie:</strong> Java, Git</li>
            <li><strong>Anno:</strong> 2023/2024</li>
            <li><strong>Specifica:</strong> <a href="https://example.com" target="_blank" rel="noopener noreferrer"> PDF</a></li>
            <li><strong>GitHub:</strong> Disponibile nel 2025</li>
            <li><strong>Stato:</strong> Completato</li>
          </ul>
        </div>
        <div className="project-right-column">
        <div className="project-images">
            <img src={Image1} alt="Project Screenshot 1" className="project-image" />
            <img src={Image2} alt="Project Screenshot 2" className="project-image" />
          </div>

          <p> 
            Questo progetto venne sviluppato in team con altri 3 studenti per la Prova Finale di Ingegneria del Software - Anno Accademico 2023/2024. Il progetto consisteva nella creazione in versione software del gioco da tavolo "Codex Naturalis", gioco della casa produttrice "Cranio Creations" in partnership con il Politecnico di Milano.
         </p> 
          <p>
          Il progetto venne realizzato interamente in Java, implementando sia interfaccia testuale TUI che grafica GUI e comunicazione Client-Server con due diverse metodologie di connessione, socket e RMI, per permettere a più giocatori di entrare in un'unica partita e giocare assieme. Il tutto richiese l'utilizzo di Git, software di controllo di versione necessario per lavorare ad un progetto in team da più persone.
          </p>
          <p>
            Per rispondere alle richieste del progetto, abbiamo utilizzato il pattern architetturale <strong>MVC ( Model-View-Controller )</strong>. L'MVC è un pattern molto diffuso nello sviluppo di applicazioni software, in quanto separa logicamente i diversi componenti dell'applicazione, migliorando la manutenibilità, la modularità e la collaborazione all'interno del team.
          </p>
          <img src={Image6} alt="Project Screenshot 2" className="project-image-large" />
          <p>
            Nello specifico, il pattern MVC è suddiviso nei seguenti componenti:
          </p>
        <div className="centered-text">
          <ul>
            <li>
              <strong>Model</strong>: Rappresenta la logica e lo stato dell'applicazione. In questo caso, il Model gestisce tutte le regole del gioco, i dati relativi alle carte, i giocatori e lo stato della partita. È il cuore del sistema, responsabile della gestione dei dati e della loro validità.
            </li>
            <li>
              <strong>View</strong>: La View è l'interfaccia con cui l'utente interagisce. Nel nostro progetto, abbiamo sviluppato sia una View grafica (GUI) che testuale (TUI), e grazie alla separazione garantita dall'MVC, entrambe le interfacce possono coesistere senza interferire con la logica del gioco. La View recupera i dati dal Model e li presenta all'utente in modo intuitivo e accessibile.
            </li>
            <li>
              <strong>Controller</strong>: Il Controller agisce come intermediario tra la View e il Model. Riceve gli input degli utenti (come le azioni di gioco) tramite l'interfaccia (che sia TUI o GUI) e li traduce in richieste che vengono passate al Model. Il Controller gestisce anche la logica di aggiornamento della View ogni volta che lo stato del Model cambia.
            </li>
          </ul>
          </div>
          <p>
            L'adozione del pattern MVC ha offerto numerosi vantaggi:
          </p>
          <div className="centered-text">
          <ul>
            <li>Ha facilitato lo sviluppo collaborativo, permettendo al team di lavorare simultaneamente su Model, View e Controller senza conflitti.</li>
            <li>Ha garantito una maggiore flessibilità, rendendo possibile la realizzazione di diverse interfacce (TUI e GUI) senza dover modificare la logica di gioco.</li>
            <li>Ha reso il codice più leggibile e mantenibile, separando le responsabilità di ciascun componente.</li>
          </ul>
          </div>
          <div className="project-title">Incarichi personali:</div>
            <p>
            Per il progetto il gruppo si organizzò dividendosi il lavoro ed io mi occupai di tre macro-aree:</p>
            <div className="centered-text">
              <ul>
            <li>
              <strong>Nel Model, logica della Board e degli obbiettivi.</strong>
           </li>
            <li>
              <strong>Connessione RMI</strong>
            </li>
            <li>
              <strong>Interfaccia Testuale ( TUI )</strong></li>
          </ul>

          <p>
           Per quanto riguarda il Model, mi sono occupato principalmente della logica di piazzamento delle carte sulla board, calcolando le posizioni disponibili (<code> availablePositions </code>) dopo il piazzamento di ogni carta e implementando un sistema flessibile per gestire i diversi obiettivi del gioco utilizzando lo <strong>Strategy Pattern</strong>.
          </p>
          </div>
          <img src={Image7} alt="Screenshot del Progetto 1" className="project-image-large" />
          <div className="centered-text">
          <p>
            Per quanto riguarda il piazzamento delle carte, la logica che ho sviluppato aggiunge nuove posizioni disponibili della griglia calcolate a partire da dove l'utente piazza la carta. 
          </p>
          <p>
            Per la gestione degli obiettivi delle carte, ho utilizzato lo <strong>Strategy Pattern</strong>, che si è rivelato ideale per gestire la varietà di obiettivi presenti. Ogni carta può avere obiettivi diversi, che devono essere valutati in base a condizioni specifiche, come il numero di simboli visibili o la configurazione delle carte sulla board. 
          </p>
          <br />
          <p>
            La seconda Area riguardava la connessione <strong>RMI ( Remote Method Invocation )</strong> in Java. Questa tecnologia permette a un'applicazione client di richiamare metodi su oggetti remoti, ovvero su server che possono essere ospitati su macchine differenti.
          </p>
          </div>
          <img src={Image5} alt="Screenshot del Progetto 1" className="project-image-large" />
          <div className="centered-text">
          <p>
            Nello specifico, ho implementato le seguenti funzionalità:
          </p>

          <ul>
            <li>
              <strong>Creazione e gestione del server RMI</strong>: Ho configurato il server affinché esponga i metodi remoti necessari per il gioco multiplayer. Questi metodi permettono ai client di interagire con lo stato del gioco e di inviare le proprie mosse, gestendo le richieste in modo asincrono.
            </li>
            <li>
              <strong>Registrazione e lookup dei client</strong>: Mi sono occupato della registrazione dei client presso il registry RMI e della gestione delle connessioni multiple, permettendo così a più giocatori di partecipare alla stessa partita.
            </li>
            <li>
              <strong>Gestione delle sessioni di gioco</strong>: Ho implementato la logica che permette ai client di avviare, unirsi e giocare a una partita in modo sincronizzato. Questo includeva la gestione dello stato condiviso del gioco e l'aggiornamento costante delle interfacce grafiche dei giocatori attraverso le chiamate RMI.
            </li>
            <li>
              <strong>Gestione degli eventi e delle notifiche</strong>: Ho sviluppato un sistema di notifiche push, che consente al server di comunicare in tempo reale con i client, notificando le mosse degli altri giocatori e aggiornando lo stato del gioco in modo sincrono.
            </li>
          </ul>

          <br />
           <p>
            La terza Area riguardava l'implementazione dell'interfaccia testuale <strong>TUI ( Text User Interface )</strong>. Questa interfaccia permette ai giocatori di interagire con il gioco direttamente dal terminale, rendendo l'esperienza di gioco accessibile anche senza l'interfaccia grafica.
          </p>
          </div>
          <img src={Image4} alt="Screenshot del Progetto 1" className="project-image-large" />
          <div className="centered-text">
          <p>
            Per realizzare la TUI, ho utilizzato cicli e strutture di controllo per leggere e visualizzare le carte del gioco direttamente a terminale. Ogni carta del gioco è stata rappresentata come un blocco di testo e disegnata utilizzando caratteri ASCII, rendendola leggibile per i giocatori. Ho fatto ampio uso della funzione <code>append()</code> per costruire dinamicamente la griglia di carte, aggiornando l'interfaccia in base alle mosse dei giocatori.
          </p>
          <p>
            Per migliorare l'esperienza visiva, ho utilizzato i <strong><em>ANSI escape codes</em></strong> per colorare le carte a terminale rendendo ogni carta distinguibile tramite colori differenti, assegnati in base al tipo di carta. Questo ha facilitato l'interazione e ha reso la TUI più intuitiva e coinvolgente, simulando visivamente gli effetti che l'interfaccia grafica avrebbe prodotto.
          </p>
          </div>
          <br />
          <p>
          Questo progetto ha rappresentato una sfida e una preziosa opportunità di crescita, permettendoci di approfondire diversi aspetti dello sviluppo software e del lavoro in team. Attraverso la combinazione di tecnologie avanzate e pattern di design, siamo riusciti a creare un prodotto completo e funzionale, simile ad uno commerciale. Personalmente credo che il progetto sia un punto cardine del percoso triennale di uno studente di Ingegneria Informatica, nonchè uno dei primi approcci al mondo del lavoro in team.
        </p>
        </div>
      </div>
    </div>
  );
};

export default Project1;
