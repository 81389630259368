import React from 'react';
import './Project.css';
import Icon from '../img/ProgettoEdernia.webp';
import Image1 from '../img/Screens/Edernia1.webp';

const Project1 = () => {
  return (
    <div className="project-container">
      <h1 className="project-title">Sito Web Edernia</h1>
      <div className="project-content">
        <div className="project-left-column">
        <img src={Icon} alt="Progetto Ing Software Icon" className="project-icon" />
          <ul className="project-details">
            <li><strong>Nome:</strong> Sito Web Edernia</li>
            <li><strong>Technologie:</strong> HTML, CSS, Javascript (React), SQL</li>
            <li><strong>Anno:</strong> 2023/2025</li>
            <li><strong>Stato:</strong> In allestimento</li>
          </ul>
        </div>
        <div className="project-right-column">
        <img src={Image1} alt="Project Screenshot 1" className="project-image-large" />

        <p> Il progetto "Sito Web Edernia" è attualmente in fase di sviluppo ed è stato creato per l'associazione di LARP (Live Action Role-Playing) Edernia. Utilizzerò Next.js per sviluppare un'applicazione web dinamica che includerà diverse funzionalità chiave: una Wiki per la documentazione del mondo di gioco, mappe interattive per esplorare le location degli eventi, e un sistema di gestione account per i giocatori. </p> <p> Ogni player avrà accesso a una sezione utente completamente personalizzabile, dove potranno gestire e modificare i dettagli del proprio personaggio. L'obiettivo è fornire un'esperienza coinvolgente e interattiva, che permetta ai giocatori di esplorare e contribuire ancora di più al mondo di Edernia, sia dentro che fuori dal gioco. </p>
        </div>
      </div>
    </div>
  );
};

export default Project1;
