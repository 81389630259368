import React from 'react';
import './Project.css';
import Icon from '../img/ProgettoMappa.webp';
import Image1 from '../img/Screens/Mappa1.webp';
import Image2 from '../img/Screens/Mappa2.webp';
import Image3 from '../img/Screens/Mappa3.webp'; 

const Project1 = () => {
  return (
    <div className="project-container">
      <h1 className="project-title">Mappa Fantasy D&D</h1>
      <div className="project-content">
        <div className="project-left-column">
          <img src={Icon} alt="Progetto Ing Software Icon" className="project-icon" />
          <ul className="project-details">
            <li><strong>Nome:</strong> Mappa Fantasy D&D</li>
            <li><strong>Technologie:</strong> HTML, CSS, Javascript (Leaflet)</li>
            <li><strong>Anno:</strong> 2022</li>
            <li><strong>Sito:</strong> <a href="https://example.com" target="_blank" rel="noopener noreferrer"> Link</a></li>
            <li><strong>Stato:</strong> Completato</li>
          </ul>
        </div>
        <div className="project-right-column">
        <div className="project-images">
            <img src={Image1} alt="Project Screenshot 1" className="project-image" />
            <img src={Image2} alt="Project Screenshot 2" className="project-image" />
          </div>

<p> 
  Questo progetto autonomo è stato realizzato per creare una mappa interattiva online che funge da repository, simile ad una Wiki, per i personaggi, le avventure e le location di un mondo fantasy, originariamente progettato per una campagna del gioco di ruolo da tavolo "Dungeons & Dragons" ( D&D ).
</p> 
<p>
  La soluzione è completamente front-end e sfrutta JavaScript in combinazione con la libreria "Leaflet", un potente strumento per la creazione di mappe interattive. Per preparare la mappa, ho utilizzato un piccolo programma in Python che ha applicato la tecnica della "piramide d'immagini" alla mappa di gioco originale. Questa strategia, utilizzata anche da "Google Maps", consente di ottimizzare il caricamento della mappa.
  </p>
  <img src={Image3} alt="Screenshot del Progetto 1" className="project-image" />
  <p>
    La tecnica della piramide d'immagini implica la suddivisione della mappa in tiles ( piastrelle ) che corrispondono ai vari livelli di zoom. In questo modo, si evita di caricare l'intera mappa in una sola volta ad altissima risoluzione, mostrando solo le tiles necessarie a seconda della scala di visualizzazione dell'utente.
  </p>
  <p>
    Sulla mappa sono stati poi inseriti popup che indicano i luoghi in cui si sono svolte le avventure principali. Cliccando su questi popup, l'utente può espandere una sidebar che presenta l'intera storia legata a quel luogo. Inoltre, sono state create sezioni dedicate alla geografia della mappa, utilizzando la funzionalità di Leaflet per tracciare poligoni. Questi poligoni, quando cliccati, rivelano una descrizione dettagliata delle aree geografiche, arricchendo ulteriormente l'esperienza dell'utente e rendendo la navigazione nella mappa più immersiva e informativa.
    </p>
    <p>
      Il progetto non solo offre un'interfaccia visivamente accattivante per esplorare il mondo di D&D, ma permette anche ai giocatori di documentare e condividere le loro avventure in un formato interattivo.
    </p>
        </div>
      </div>
    </div>
  );
};

export default Project1;
