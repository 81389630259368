import './App.css';
import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import Navbar from './Navbar';
import HomePage from './MyPage';
import Carousel from './Carousel';
import ContactMe from './ContactMe';
import Curriculum from './Curriculum';
import { Analytics } from "@vercel/analytics/react"

const AnimatedRoutes = () => {
  const location = useLocation();

  return (
    <TransitionGroup className="transition-group">
      <CSSTransition
        key={location.pathname}
        classNames="fade"
        timeout={300}
      >
        <div className="route-section">
          <Routes location={location}>
            <Route path="/" element={<HomePage />} />
            <Route path="/Progetti" element={<Carousel />} />
            <Route path="/Contattami" element={<ContactMe />} />
            <Route path="/Curriculum" element={<Curriculum />} />
          </Routes>
        </div>
      </CSSTransition>
    </TransitionGroup>
  );
};

function App() {
  return (
    <div className="App">
      <Analytics/>
      <Router>
        <div style={{ display: 'flex' }}>
          <Navbar />
          <div style={{ width: '100%', overflow: 'hidden' }}>
            <AnimatedRoutes />
          </div>
        </div>
        
      </Router>
    </div>
  );
}

export default App;