import React from 'react';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-section about">
          <h2>Chi sono</h2>
          <p>
            Sono uno studente prossimo alla laurea con una passione per la creazione di applicazioni web moderne,
            interessato allo sviluppo full-stack.
          </p>
        </div>

        <div className="footer-section contact">
          <h2>Contatti</h2>
          <p>Email: andreastagno80@gmail.com</p>
          <p>Telefono: +39 392 8122089</p>
        </div>

        <div className="footer-section social-media">
          <h2>Seguimi</h2>
          <div className="social-icons">
            <i className="fab fa-github"></i>
            <i className="fab fa-linkedin"></i>
            <i className="fab fa-twitter"></i>
            <i className="fab fa-instagram"></i>
          </div>
        </div>
      </div>

      <div className="footer-bottom">
        <p>&copy; {new Date().getFullYear()} Andrea Stagno. Tutti i diritti riservati.</p>
      </div>
    </footer>
  );
};

export default Footer;
