import React from 'react';
import './Project.css';
import Icon from '../img/ProgettoWEB.webp';

const Project1 = () => {
  return (
    <div className="project-container">
      <h1 className="project-title">Progetto Tecnologie Informatiche per il Web</h1>
      <div className="project-content">
        <div className="project-left-column">
        <img src={Icon} alt="Progetto Ing Software Icon" className="project-icon" />
          <ul className="project-details">
            <li><strong>Nome:</strong> Progetto Tecnologie Informatiche per il Web</li>
            <li><strong>Voto:</strong> -</li>
            <li><strong>Technologie:</strong> HTML, CSS, Javascript, Java, SQL</li>
            <li><strong>Anno:</strong> 2023/2024</li>
            <li><strong>Specifica</strong> <a href="https://example.com" target="_blank" rel="noopener noreferrer"> PDF:</a></li>
            <li><strong>GitHub:</strong> Disponibile nel 2025</li>
            <li><strong>Stato:</strong> In svolgimento</li>
          </ul>
        </div>
        <div className="project-right-column">
<p>
Questo progetto ha riguardato la realizzazione di un'applicazione web client-server per la gestione di gruppi di lavoro, progettata per facilitare la creazione, la gestione e l'invito di utenti ai gruppi in modo interattivo e dinamico. L'applicazione è stata implementata utilizzando Servlet in Java per gestire la logica lato server, garantendo così una robusta interazione tra client e server.
</p>
<p>
L'applicazione prevedeva un sistema di registrazione e login, dove l'utente poteva creare un account e autenticarsi per accedere all'area riservata. La registrazione includeva un controllo sulla validità dell'indirizzo email e verificava la corrispondenza tra i campi "password" e "ripeti password", oltre a garantire l'unicità dello username. Una volta effettuato il login, l'utente accedeva alla homepage, che mostrava l'elenco dei gruppi creati da lui o ai quali era stato invitato, purché fossero ancora attivi, oltre a una form per la creazione di nuovi gruppi.
</p> 
 <p>
  Ogni gruppo aveva un titolo, una data di creazione, una durata in giorni, e un numero minimo e massimo di partecipanti. Quando l'utente creava un nuovo gruppo, veniva mostrata una pagina con l'elenco degli utenti registrati, ordinati alfabeticamente per cognome, da cui poteva selezionare i partecipanti. Se il numero di invitati non rispettava i vincoli minimi o massimi, veniva visualizzato un messaggio di avviso e l'utente era invitato a correggere la selezione. La selezione dei partecipanti era dinamica, e gli utenti scelti rimanevano preselezionati, facilitando la gestione anche in caso di modifiche.
  </p>
  <p>
    Una delle funzionalità più avanzate dell'applicazione era l'uso di JavaScript per realizzare un'interfaccia a singola pagina: tutte le interazioni dell'utente, dalla creazione del gruppo alla gestione dei partecipanti, avvenivano senza il completo ricaricamento della pagina. Questo era possibile grazie all'invocazione asincrona delle Servlet Java, che aggiornavano dinamicamente i contenuti rilevanti.
    </p>
    <p>
      L'applicazione introduceva anche un sistema per la gestione dei partecipanti nella pagina dei dettagli del gruppo. Se l'utente era il creatore del gruppo, poteva trascinare i nomi dei partecipanti su un'icona del cestino per rimuoverli. Il sistema verificava sempre che fosse rispettato il numero minimo di partecipanti prima di completare l'operazione, garantendo così la correttezza dei dati e la stabilità del gruppo.
      </p>
      <p>
        L'interfaccia utilizzava finestre modali per gestire la selezione degli utenti e i messaggi di errore o conferma, migliorando l'esperienza utente con un flusso pulito e intuitivo. Inoltre, tutti i controlli relativi al numero minimo e massimo di partecipanti venivano eseguiti sia lato client che lato server, offrendo un'interazione fluida e senza interruzioni. </p> <p> Questo progetto ha rappresentato una soluzione completa per la gestione dei gruppi di lavoro online, combinando le funzionalità avanzate dei Servlet in Java con un design interattivo in JavaScript, per migliorare la collaborazione e la gestione delle attività di gruppo.

        </p>
          
        </div>
      </div>
    </div>
  );
};

export default Project1;
