import React, { useState, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import './MyPage.css';
import Icon1 from './img/Progetti.webp';
import Icon2 from './img/Curriculum.webp';
import Icon3 from './img/Contattami.webp'
import { Link } from 'react-router-dom';
import Competenze from "./Competenze";
import Footer from "./Footer";

const Homepage = () => {
  const [ref1, inView1] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [refButtons, inViewButtons] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [isScrolled, setIsScrolled] = useState(false);
  
  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 420);
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  });

  return (
    <div className="homepage">
      <header className="hero-section">
        <div className="hero-content-left">
        </div>

   <div className="hero-content-right">
   <h1 className={`hero-title ${inView1 ? 'fade-in' : ''}`} ref={ref1}>
            Ciao! Sono Andrea Stagno
          </h1>
          <h2 className={`hero-subtitle ${inView1 ? 'fade-in' : ''}`} ref={ref1}>
            Sono uno studente di Ingegneria Informatica al Politecnico di Milano prossimo alla laurea ( 15 CFU rimanenti! ). Ho la passione per la programmazione con un interesse in quella Full-Stack. Mi piace affrontare nuove sfide, sia dal punto di vista del codice che dell'architettura delle applicazioni. In questo momento, sono alla ricerca di opportunità per applicare e migliorare le mie competenze, sperimentando nuove tecnologie e approcci. Dai un'occhiata a quello che ho fatto, scarica il mio curriculum e/o contattami!
          </h2>
     </div>
        <div 
          className={`button-container`} 
          ref={refButtons}
        >
          <div className={`text-box one ${inViewButtons ? 'slide-in-one' : ''} ${isScrolled ? 'text-box-hide' : ''}`} >
            <Link to="/Progetti" className="btn btn-white btn-animate">
              <div className="icon-wrapper">
                <img src={Icon1} alt="Progetti" />
              </div>
              Progetti
            </Link>
          </div>
          <div className={`text-box two ${inViewButtons ? 'slide-in-two' : ''} ${isScrolled ? 'text-box-hide' : ''}`}>
            <Link to="/Curriculum" className="btn btn-white btn-animate">
              <div className="icon-wrapper">
                <img src={Icon2} alt="Curriculum" />
              </div>
              Curriculum
            </Link>
          </div>
          <div className={`text-box three ${inViewButtons ? 'slide-in-three' : ''} ${isScrolled ? 'text-box-hide' : ''}`}>
            <Link to="/Contattami" className="btn btn-white btn-animate">
              <div className="icon-wrapper">
                <img src={Icon3} alt="Contattami" />
              </div>
              Contattami
            </Link>
          </div>
        </div>
      </header>
      <Competenze/>
      <Footer/>
    </div>
  );
};

export default Homepage;
