import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import './Navbar.css';
import logo from './img/FotoCurriculum.png';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const location = useLocation();
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  // Effetto per gestire lo scroll e chiudere la navbar su mobile
  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 420);  
      // Chiudi il menu se è aperto e l'utente sta scrollando su mobile
      if (isOpen && isMobile) {
        setIsOpen(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, [isOpen, isMobile]);

  // Chiudi il menu cliccando all'esterno
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isOpen && !event.target.closest('.navbar') && !event.target.closest('.hamburger')) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [isOpen]);

  // Toggle del menu mobile
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  // Gestione del click sugli elementi del menu
  const handleNavItemClick = () => {
    setIsUserMenuOpen(false);
    if (isMobile) setIsOpen(false); // Chiudi il menu se sei su mobile
    window.scrollTo({
      top: 0
    }); 
  };

  const isHomeActive = location.pathname === '/' || location.pathname === '/home';

  const navItems = [
    { name: 'Chi sono', path: '/' },
    { name: 'Progetti', path: '/Progetti' },
    { name: 'Curriculum', path: '/Curriculum' },
    { name: 'Contattami', path: '/Contattami' }
  ];

  return (
    <div>
      <div className={`menu-title ${isOpen || (isHomeActive && !isScrolled) ? 'menu-hidden' : ''}`}>Menu</div>
      <div className={`navbar-wrapper ${isOpen && !(isHomeActive && !isScrolled) ? 'open' : ''} ${isHomeActive && !isScrolled ? 'navbar-wrapper-home' : ''}`}>
        <div className={`navbar ${isHomeActive && !isScrolled ? 'navbar-home' : ''} ${isOpen && !(isHomeActive && !isScrolled) ? 'open' : ''}`}>
          <div className="nav-content">
            {/* Logo */}
            <div className="logo-container">
              <img
                src={logo}
                alt="logo"
                className={`logo ${isHomeActive && !isScrolled ? 'logo-home' : ''}`}
                onClick={toggleMenu}
              />
            </div>

            {/* Link di navigazione */}
            <div className={`nav-links ${isMobile ? 'mobile' : ''} ${isOpen && !(isHomeActive && !isScrolled) ? 'open' : ''}`}>
              {navItems.map((item) => (
                <Link
                  key={item.path}
                  to={item.path}
                  className={`nav-item ${location.pathname === item.path ? 'active' : ''}`}
                  onClick={handleNavItemClick}
                >
                  {item.name}
                </Link>
              ))}
            </div>

          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
