import React from 'react';
import {  Code, Database, Terminal, Globe, Cpu, GitBranch, FileText, Monitor, Layout, Camera, Cuboid, Key, Hash, Coffee} from 'lucide-react';
import './Competenze.css';

const skills = [
  { name: 'JavaScript', icon: <Code />, description: 'Esperienza nello sviluppo front-end con JavaScript e framework come React.' },
  { name: 'Java', icon: <Coffee />, description: 'Esperienza nello sviluppo di applicazioni Java e framework JavaFX e Swing.' },
  { name: 'C', icon: <Hash />, description: 'Competenza nella programmazione di basso livello C e strumenti per debugging e profiling.' },
  { name: 'SQL', icon: <Database />, description: 'Abile nella gestione e query di database relazionali.' },
  { name: 'HTML/CSS', icon: <Globe />, description: 'Abile nella creazione di layout responsive e design moderni.' },
  { name: 'Python', icon: <Terminal />, description: 'Competenza intermedia in analisi dati e automazione con Python.' },
  { name: 'UI/UX', icon: <Layout />, description: 'Competenze nella progettazione di interfacce utente intuitive e esperienze utente coinvolgenti.' },
  { name: 'VHDL', icon: <Cpu />, description: 'Conoscenza di base nella progettazione di hardware digitale utilizzando VHDL.' },
  { name: 'Git', icon: <GitBranch />, description: 'Abile nell\'uso di Git per il controllo versione e la collaborazione nei progetti.' },
  { name: 'Pacchetto Office', icon: <FileText />, description: 'Competenza avanzata nell\'uso di Microsoft Office anche per produttività aziendale.' },
  { name: 'Photoshop, Gimp', icon: <Camera />, description: 'Esperienza nell\'uso di Photoshop / Gimp per creazione e modifica foto.' },
  { name: 'Blender', icon: <Cuboid />, description: 'Capacità base nell\' uso di Blender per la creazione di asset' },
  { name: 'Unity', icon: <Key />, description: 'Capacità base nell\' uso di Unity per la produzione di app e videeogiochi' },
  { name: 'Sistemi Operativi', icon: <Monitor />, description: 'Familiarità con Windows, macOS e distribuzioni Linux per amministrazione e uso quotidiano.' },
];

const Competenze = () => {

  return (
    <div className={`language-chessboard dark-mode`}>
      <div className="container">
      <h1 className="skills-title">Le mie competenze informatiche:</h1>
        <div className="language-grid">
          {skills.map((skill, index) => (
            <div key={skill.name} className={`language-item ${index % 2 === 0 ? 'even' : 'odd'}`}>
              <div className="language-content">
                <div className="language-info">
                  <h2 className="language-name">{skill.name}</h2>
                  <p className="language-description">{skill.description}</p>
                </div>
                <div className="language-icon">
                  {React.cloneElement(skill.icon, { size: 48 })}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Competenze;