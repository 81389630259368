import React, { useState } from 'react';
import './ContactMe.css';
import Footer from './Footer';

const Contattami = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');




  const handleSubmit = async (e) => {
    e.preventDefault();

    const res = await fetch('/api/sendEmail', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        name,
        email,
        message,
      }),
    });

    if (res.ok) {
      console.log('Email inviata con successo');
    } else {
      console.log('Errore nell\'invio dell\'email');
    }

    setName('');
    setEmail('');
    setMessage('');
  };

  return (
    <div className="contattami-container">
      <h1>Contattami</h1>
      <form onSubmit={handleSubmit} className="contact-form">
        <div className="input-group">
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
            placeholder=""
          />
          <label>Nome</label>
        </div>
        <div className="input-group">
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            placeholder=""
          />
          <label>Email</label>
        </div>
        <div className="input-group">
          <textarea
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            required
            placeholder=""
          ></textarea>
          <label>Messaggio</label>
        </div>
        <button type="submit" className="submit-btn">Invia</button>
      </form>
      <br />
      <br />
      <Footer/>
    </div>
  );
};

export default Contattami;
