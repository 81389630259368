import React, { useState } from 'react';
import './Curriculum.css';
import CurriculumPreview from './img/CurriculumPreview.jpg';
import CurriculumPdf from './img/Curriculum Vitae Andrea Stagno.pdf'
import { Download } from 'lucide-react';
import Footer from './Footer';

const Curriculum = () => {
  const [hovered, setHovered] = useState(false);

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = CurriculumPdf;
    link.download = 'Curriculum Vitae Andrea Stagno.pdf';
    link.click();
  };

  return (
    <div>
      <div className="curriculum-container">
        <h2 className="curriculum-title">Scarica il mio Curriculum</h2>
        <div
          className={`curriculum-image-container ${hovered ? 'hovered' : ''}`}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onClick={handleDownload}
        >
          <img
            src={CurriculumPreview}
            alt="Curriculum Preview"
            className="curriculum-preview-image"
          />
          {hovered && (
            <div className="curriculum-overlay">
              <Download className="download-icon" size={40} />
            </div>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Curriculum;
