import React from 'react';
import './Project.css';
import Icon from '../img/ProgettoC.webp';
import Image1 from '../img/Screens/C1.webp'; 
import Image2 from '../img/Screens/C2.webp'; 
import Image3 from '../img/Screens/C3.webp';

const Project1 = () => {
  return (
    <div className="project-container">
      <h1 className="project-title">Progetto di Algoritmi e Strutture Dati</h1>
      <div className="project-content">
        <div className="project-left-column">
        <img src={Icon} alt="Progetto Ing Software Icon" className="project-icon" />
          <ul className="project-details">
            <li><strong>Nome:</strong> Prova Finale: progetto Algoritmi e Strutture Dati</li>
            <li><strong>Voto:</strong> 30</li>
            <li><strong>Technologie:</strong> C, Tools per Debugging C</li>
            <li><strong>Anno:</strong> 2023/2024</li>
            <li><strong>Specifica:</strong> <a href="https://example.com" target="_blank" rel="noopener noreferrer"> PDF</a></li>
            <li><strong>GitHub:</strong> Disponibile nel 2025</li>
            <li><strong>Stato:</strong> Completato</li>
          </ul>
        </div>
        <div className="project-right-column">
        <img src={Image3} alt="Project Screenshot 1" className="project-image-large" />
        <p>
        Questo progetto richiedeva la realizzazione di un programma in C atto a simulare il funzionamento di una pasticceria industriale, focalizzandosi sulla gestione 
        degli ordini e del magazzino degli ingredienti. La simulazione è a tempo discreto e si sviluppa 
        attraverso una serie di comandi che gestiscono le ricette, i rifornimenti di ingredienti, gli ordini 
        dei clienti e la logistica dei corrieri. L'obiettivo è ottimizzare la gestione delle risorse della 
        pasticceria, permettendo la preparazione dei dolci in modo efficiente e risolvendo eventuali ordini in attesa 
        in base alla disponibilità degli ingredienti.
        </p>

        <p>
            La pasticceria utilizza un sistema in cui ogni ordine viene gestito in base alla disponibilità degli ingredienti, 
            con una logica che privilegia i lotti di ingredienti più vicini alla scadenza. Inoltre, è implementato un sistema di 
            gestione della consegna degli ordini tramite corrieri con una capienza limitata, che caricano i dolci in base al 
            peso e all'ordine di arrivo degli ordini. La simulazione termina con la lettura dell'ultimo comando di ingresso.
        </p>
        <p>
          Durante lo sviluppo del progetto per la gestione degli ordini di una pasticceria industriale, mi sono concentrato sulla creazione di un programma il più efficiente possibile, in grado di simulare il funzionamento della pasticceria a tempo discreto. 
          </p>

        <p>
          Per realizzare il progetto, ho dovuto scegliere con attenzione le strutture più adatte per gestire gli ingredienti del magazzino, gli ordini in attesa e i lotti di ingredienti con scadenze diverse. La scelta delle giuste strutture dati è stata fondamentale per garantire che la gestione degli ordini e degli ingredienti avvenisse nel minor tempo possibile, evitando ritardi nella simulazione e garantendo che gli ordini in sospeso venissero evasi nel giusto ordine cronologico. In particolare, ho sviluppato algoritmi efficienti per gestire la priorità di utilizzo degli ingredienti in base alla scadenza e per organizzare il carico dei camioncini del corriere in modo ottimale.
        </p>
        <img src={Image1} alt="Project Screenshot 1" className="project-image" />
        <img src={Image2} alt="Project Screenshot 2" className="project-image" />
        <p>
          Uno degli aspetti chiave del progetto è stata l'eliminazione dei colli di bottiglia, che avrebbero potuto rallentare l'esecuzione della simulazione man mano che il numero di ordini e di lotti di ingredienti aumentava. Per identificare e risolvere questi problemi di prestazioni, ho utilizzato strumenti di profiling del codice, come <strong>Valgrind</strong> e <strong>Callgrind</strong>. Questi strumenti mi hanno permesso di analizzare in dettaglio l'efficienza del codice, individuando le parti critiche che necessitavano di ottimizzazione. Attraverso l'analisi dei dati di profiling, sono riuscito a migliorare la gestione della memoria e a ridurre significativamente il tempo di esecuzione di operazioni complesse.
        </p>

        <p>
          Durante lo sviluppo del progetto, ho imparato l'importanza di una gestione efficiente delle risorse. Ho anche acquisito una maggiore comprensione di come affrontare problemi di ottimizzazione in progetti su larga scala e di come l'uso di strumenti di analisi delle prestazioni possa fare la differenza nell'identificazione e risoluzione di problemi nascosti nel codice.
        </p>
        <p>
          Il codice, così come la spiegazione delle strutture dati utilizzate e degli ostacoli trovati nel percorso saranno disponibili nel 2025 dopo chiusura dell'anno accademico 2023/2024 per evitarne il plagio.
        </p>
        </div>
      </div>
    </div>
  );
};

export default Project1;
