import React from 'react';
import './Project.css';
import Icon from '../img/ProgettoCarte.webp';
import Image1 from '../img/Screens/Carte1.webp';
import Image2 from '../img/Screens/Carte2.webp'; 

const Project1 = () => {
  return (
    <div className="project-container">
      <h1 className="project-title">"Isaac: Four Souls" Card Creator</h1>
      <div className="project-content">
        <div className="project-left-column">
        <img src={Icon} alt="Progetto Ing Software Icon" className="project-icon" />
          <ul className="project-details">
            <li><strong>Nome:</strong> "Isaac: Four Souls" Card Creator</li>
            <li><strong>Technologie:</strong> HTML, CSS, Javascript</li>
            <li><strong>Anno:</strong> 2023</li>
            <li><strong>Sito:</strong> <a href="https://example.com" target="_blank" rel="noopener noreferrer"> Link</a></li>
            <li><strong>Stato:</strong> In Attesa</li>
          </ul>
        </div>
        <div className="project-right-column">
        <img src={Image1} alt="Project Screenshot 1" className="project-image" />
        <img src={Image2} alt="Project Screenshot 2" className="project-image" />
        <p>
        Questo progetto autonomo consiste nella creazione di un <strong>Card Maker online</strong> per il gioco di carte "Isaac Four Souls" implementato tutto front-end con javascript. L'obiettivo era sviluppare una piattaforma interattiva che consentisse agli utenti di progettare, personalizzare e stampare le proprie carte. Gli utenti possono selezionare variabili come il tipo di carta, le illustrazioni, le abilità e i testi, permettendo così una personalizzazione totale delle carte da gioco.
        </p>

        <p>
        La piattaforma è stata progettata per essere intuitiva e facile da usare, con un'interfaccia utente che guida gli utenti attraverso il processo di creazione. Ogni carta può essere visualizzata in tempo reale mentre vengono effettuate modifiche, garantendo un'esperienza di creazione dinamica. Inoltre, il sistema include un'opzione di salvataggio, permettendo agli utenti di salvare nel pc le proprie creazioni.
        </p>

        <p>
          Al momento il progetto è in attesa ed in futuro vorrei ricrearlo utilizzando framework come react o simili.
        </p>
        </div>
      </div>
    </div>
  );
};

export default Project1;
