import React, { useEffect, useState } from 'react';
import './Carousel.css';
import Progetto1 from './img/ProgettoIngSoftware.webp';
import Progetto2 from './img/ProgettoC.webp';
import Progetto3 from './img/ProgettoVHDL.webp';
import Progetto4 from './img/ProgettoWEB.webp';
import Progetto5 from './img/ProgettoEdernia.webp';
import Progetto6 from './img/ProgettoCarte.webp';
import Progetto7 from './img/ProgettoMappa.webp';
import Footer from './Footer';

import Project1 from './projects/ProjectIngSoftware';
import Project2 from './projects/ProjectC';
import Project3 from './projects/ProjectVHDL';
import Project4 from './projects/ProjectWEB';
import Project5 from './projects/ProjectEdernia';
import Project6 from './projects/ProjectCarte';
import Project7 from './projects/ProjectMappa';

const Carousel = () => {
  // State to keep track of the current project index
  const [currentIndex, setCurrentIndex] = useState(0);
  const [oldIndex, setOldIndex] = useState(0);
  const [rotationAngle, setRotationAngle] = useState(0);
  const [giro, setGiro] = useState(0);
  const [count, addCount] = useState(0);
  const [isScrolled, setIsScrolled] = useState(false);

  const characters = [
    { src: Progetto1, component: <Project1 /> },
    { src: Progetto2, component: <Project2 /> },
    { src: Progetto3, component: <Project3 /> },
    { src: Progetto4, component: <Project4 /> },
    { src: Progetto5, component: <Project5 /> },
    { src: Progetto6, component: <Project6 /> },
    { src: Progetto7, component: <Project7 /> }
  ];

  const opzioni = characters.length;

  const arrowIndexRight = (currentIndex + 1) >= opzioni ? 0 : currentIndex + 1;
  const arrowIndexLeft = (currentIndex - 1) < 0 ? opzioni - 1 : currentIndex - 1;

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 770);
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    renderCarousel();
  }, [currentIndex]); 

  const calculateDistance = (index) => {
    let distanza = index - currentIndex;
    if (distanza > opzioni / 2 || distanza < -opzioni / 2) {
      setGiro((prevGiro) => prevGiro - Math.sign(distanza));
      distanza = (opzioni - Math.abs(distanza)) * -Math.sign(distanza);
    }
    return distanza;
  };

  const handleCharacterClick = (index) => {
    if(index == currentIndex){
      window.scrollTo({
        top: 500,
        behavior: 'smooth' 
      });
    }else{
      setOldIndex(currentIndex);
      const distanza = calculateDistance(index);
      const newRotationAngle = rotationAngle + distanza * (-360 / opzioni);
      setCurrentIndex(index);
      setRotationAngle(newRotationAngle);
      addCount(count + 1);
    }
  };


  const renderCarousel = () => {
    const carousel = document.querySelector('.carousel');
    if (carousel) {
      carousel.style.transform = `rotateY(${rotationAngle}deg)`;

      const charactersElements = document.querySelectorAll('.character');
      charactersElements.forEach((characterElement, index) => {
        const characterRotationAngle = index * 360 / opzioni;
        const distanza = calculateDistance(index);
        const style = `rotateY(${characterRotationAngle}deg) translateZ(15vw) translateY(${distanza}px) rotate3d(0, 1, 0, ${((currentIndex - index) * 360 / opzioni + 360 * giro)}deg)`;
        characterElement.style.transform = style;

        const distanceToCurrent = Math.abs(currentIndex - index);
        const normalizedDistance = Math.min(distanceToCurrent, opzioni - distanceToCurrent);
        const opacity = 1.1 - (normalizedDistance / (opzioni / 2)) * 1.2; // 0 for selected, 1 for farthest
        characterElement.style.opacity = opacity; 

      });
    }
  };

  return (
    <div>
      <div className="carousel-container">
        <div className="carousel">
          {characters.map((character, index) => {
            const characterRotationAngle = index * 360 / opzioni;
            const distanceToCurrent = Math.abs(currentIndex - index);
            const normalizedDistance = Math.min(distanceToCurrent, opzioni - distanceToCurrent);
            const opacity = 1 - (normalizedDistance / (opzioni / 2));

            const style = {
              transform: `rotateY(${characterRotationAngle}deg) translateZ(15vw) rotate3d(0, 1, 0, ${((currentIndex - index) * 360 / opzioni + 360 * giro)}deg)`,
              opacity: opacity, 
              transition: 'transform 0.5s ease-in-out, opacity 0.5s ease-in-out', 
            };

            return (
              <div key={index} className="character" onClick={() => handleCharacterClick(index)} style={style}>
                <img className="icons" src={character.src} alt={`Character ${index}`} />
              </div>
            );
          })}
        </div>
      </div>
      <div className={`arrowLeft ${isScrolled ? 'arrow-hidden' : ''}`} onClick={() => handleCharacterClick(arrowIndexLeft)}>
        <div className="arrow left"></div>
      </div>
      <div className={`arrowRight ${isScrolled ? 'arrow-hidden' : ''}`} onClick={() => handleCharacterClick(arrowIndexRight)}>
        <div className="arrow right"></div>
      </div>
      <div className={`arrowBottom ${isScrolled ? '' : 'arrow-hidden'}`} onClick={() => window.scrollTo({ top: 0, behavior: 'smooth'})}>
        <div className="arrow bottom"></div>
      </div>
      <div className="character-details">
        {characters[currentIndex].component}
      </div>
      <Footer/>
    </div>
  );
};

export default Carousel;
