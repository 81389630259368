import React from 'react';
import './Project.css';
import Icon from '../img/ProgettoVHDL.webp';
import Image1 from '../img/Screens/VHDL1.webp';
import Image2 from '../img/Screens/VHDL2.webp';

const Project1 = () => {
  return (
    <div className="project-container">
      <h1 className="project-title">Progetto di Reti Logiche</h1>
      <div className="project-content">
        <div className="project-left-column">
        <img src={Icon} alt="Progetto Ing Software Icon" className="project-icon" />
          <ul className="project-details">
            <li><strong>Nome:</strong> Prova Finale: Progetto di Reti Logiche</li>
            <li><strong>Voto:</strong> 27</li>
            <li><strong>Technologie:</strong> VHDL, Vivado</li>
            <li><strong>Anno:</strong> 2023/2024</li>
            <li><strong>Specifica</strong> <a href="https://example.com" target="_blank" rel="noopener noreferrer"> PDF:</a></li>
            <li><strong>GitHub:</strong> Disponibile nel 2025</li>
            <li><strong>Stato:</strong> Completato</li>
          </ul>
        </div>
        <div className="project-right-column">
        <img src={Image1} alt="Project Screenshot 1" className="project-image-large" />
        <p>
    Questo progetto richiese l'implementazione di un modulo hardware, descritto in VHDL, che si interfacciasse con una memoria per elaborare una sequenza di parole. Il modulo doveva leggere una sequenza di K parole, completare eventuali parole mancanti sostituendo i valori "zero" con l'ultimo valore non zero letto e inserire un valore di "credibilità" C associato a ogni parola.
</p>

<p>
    La sequenza di K parole venne letta da una memoria a partire da un indirizzo specificato, e il modulo doveva completare la sequenza e scrivere il risultato finale nella memoria. Il valore di "credibilità" veniva mantenuto costante a 31 quando la parola era valida (diversa da zero) e veniva decrementato ad ogni zero, fino a un minimo di 0.
</p>

<p>
    L'obiettivo fu implementare il modulo su FPGA con l'utilizzo di Xilinx Vivado Webpack, garantendo un corretto funzionamento con un periodo di clock minimo di 20 ns.
</p>
<img src={Image2} alt="Project Screenshot 1" className="project-image-large" />
<p>
    Durante la realizzazione del progetto, mi concentrati sulla corretta implementazione della logica di elaborazione della sequenza di parole in VHDL, con particolare attenzione all'ottimizzazione del modulo per il rispetto del periodo di clock richiesto. La progettazione fu suddivisa in diverse fasi, partendo dalla comprensione della specifica e dalla modellazione del sistema attraverso una FSM (Finite State Machine) per gestire i diversi stati di lettura, sostituzione e scrittura dei dati nella memoria.
</p>

<p>
    La gestione della memoria fu progettata per consentire una lettura e scrittura efficienti dei dati, evitando colli di bottiglia e garantendo che la sostituzione dei valori mancanti fosse eseguita correttamente. In questa fase, fu importante gestire la logica che controllava il valore di "credibilità", assicurando che venisse decrementato nel modo corretto ogni volta che si incontrava uno zero nella sequenza.
</p>

<p>
    Per migliorare l'efficienza e garantire che il modulo rispettasse i requisiti di timing, utilizzai strumenti di sintesi e analisi delle prestazioni forniti da Xilinx Vivado Webpack. In particolare, grazie all'uso di tool di profiling come il Timing Analyzer, riuscii a identificare e risolvere eventuali problemi di timing, assicurandomi che il sistema funzionasse con il periodo di clock richiesto di almeno 20 ns.
</p>

    <p>
    Il codice, così come la spiegazione degli ostacoli trovati nel percorso saranno disponibili nel 2025 dopo chiusura dell'anno accademico 2023/2024 per evitarne il plagio.
    </p>
        </div>
      </div>
    </div>
  );
};

export default Project1;
